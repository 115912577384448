import React from 'react';
import ModuleWrapper from '@/components/modules/ModuleWrapper/ModuleWrapper';
import SectionRichText from '../SectionRichText';
import type { CTASectionTypes } from './CTASection.types';
import * as styles from './CTASection.module.scss';

const CTASection = ({
  eyebrow,
  body,
  background,
  variant,
  top_spacing = 'md',
  bottom_spacing = 'md',
  mobile_top_spacing = 'none',
  mobile_bottom_spacing = 'none',
  className,
}: CTASectionTypes) => {
  const castedBackground = String(background);
  const castedVariant = String(variant === 'narrow');

  return (
    <ModuleWrapper
      topSpacing={top_spacing}
      bottomSpacing={bottom_spacing}
      mobileTopSpacing={mobile_top_spacing}
      mobileBottomSpacing={mobile_bottom_spacing}
      tag="section"
      outsideSpacing
    >
      <div
        className={styles.content}
        data-background={castedBackground}
        data-top-spacing={String(top_spacing)}
        data-bottom-spacing={String(bottom_spacing)}
      >
        {eyebrow && (
          <p className={styles.eyebrow} data-white-variant={String(background === 'blue')} data-narrow={castedVariant}>
            {eyebrow}
          </p>
        )}
        <div className={styles.richTextWrapper} data-background={castedBackground} data-narrow={castedVariant}>
          <SectionRichText body={body} className={className || styles} gridAlign="center" />
        </div>
      </div>
    </ModuleWrapper>
  );
};

export default CTASection;
